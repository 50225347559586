<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
       <!-- FILTROS -->
       <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-btn
            style="position: absolute; top: -15px; right: 55px; z-index: 1"
            color="success"
            title="Cargar incidente"
            small
            fab
            @click="dialog = true; titulo = null"
          >
            <v-icon>fas fa-plus</v-icon>
          </v-btn>
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar()">
              <v-row>
                <v-col cols="5" sm="2" class="py-1">
                  Número
                  <v-text-field
                    v-model.trim="filtro.numero"
                    tabindex="1"
                    type="number"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="7" sm="3" md="4" class="py-1">
                  Asunto
                  <v-text-field
                    v-model.trim="filtro.asunto"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="3" xl="2" class="py-1">
                  Estado
                  <v-autocomplete
                    v-model="filtro.estado"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="inc_estados"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="4" md="3" xl="2" class="py-1">
                  Usuario asignado
                  <v-autocomplete
                    v-model="filtro.asignado"
                    item-text="nombre"
                    item-value="usuario_id"
                    :items="personal_mesa"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="5" md="4" class="py-1">
                  Solicitante
                  <v-autocomplete
                    v-model="filtro.solicitante"
                    item-text="nombre"
                    item-value="personal_id"
                    :items="solicitantes"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="7" md="5" xl="4">
                  <v-row>
                    <v-col class="py-1">
                      Fec. creac. desde
                      <FechaPicker
                        v-model="filtro.desde_sol"
                        hide-details
                        clearable
                      />
                    </v-col>
                    <v-col class="py-1">
                      Fec. creac. hasta
                      <FechaPicker
                        v-model="filtro.hasta_sol"
                        hide-details
                        clearable
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="4" md="3" xl="2" class="py-1">
                  Usuario finalización
                  <v-autocomplete
                    v-model="filtro.finalizacion"
                    item-text="nombre"
                    item-value="usuario_id"
                    :items="personal_mesa"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="7" md="5" xl="4">
                  <v-row>
                    <v-col class="py-1">
                      Fec. finaliz. desde
                      <FechaPicker
                        v-model="filtro.desde_fin"
                        hide-details
                        clearable
                      />
                    </v-col>
                    <v-col class="py-1">
                      Fec. finaliz. hasta
                      <FechaPicker
                        v-model="filtro.hasta_fin"
                        hide-details
                        clearable
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <BtnFiltro
                  :loading="load"
                  @clear="limpiar()"
                />
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- DATA TABLE -->
      <v-data-table
        v-model="selected"
        class="cebra elevation-2 mt-2"
        sort-by="id"
        :items="incidentes"
        :headers="headers"
        :loading="load"
        :search="search"
        single-select
        sort-desc
        dense
        @click:row="rowClick"
      >
        <!-- busqueda local -->
        <template v-slot:top>
          <v-row class="d-flex justify-end pa-2" no-gutters>
            <v-col cols="6" sm="3" xl="2">
              <SearchDataTable
                v-model="search"
              />
            </v-col>
          </v-row>
        </template>
        <!-- fechas -->
        <template v-slot:[`item.fecha_creacion`]="{ value }">
          {{ moment(value).format('DD/MM/YY [-] HH:mm') }}
        </template>
        <template v-slot:[`item.fecha_finalizacion`]="{ value }">
          {{ value ? moment(value).format('DD/MM/YY [-] HH:mm') : null }}
        </template>
        <!-- estado colores -->
        <template v-slot:[`item.estado_nombre`]="{ item }">
          <v-chip
            :color="inc_estados.find(e => e.id == item.estado) ? inc_estados.find(e => e.id == item.estado).color : ''"
            small
          >
            {{ item.estado_nombre }}
          </v-chip>
        </template>
        <!-- no data -->
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
      </v-data-table>
      <!-- VISUALIZACION DE INCIDENTES -->
      <v-expand-transition>
        <section id="detalle">
          <!-- DETALLE -->
          <v-card v-if="selected.length > 0" class="my-2">
            <v-card-title class="d-flex justify-end pb-0 pt-1 pr-1">
              <v-btn
                title="Cerrar detalle"
                icon
                x-small
                @click="selected = []"
              >
                <v-icon>fas fa-times</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-title class="py-2" style="word-break: normal">
              Nº {{ selected[0].id }} - {{ selected[0].estado == 3 || !mismo_usuario ? selected[0].asunto : '' }}
              <v-text-field
                v-if="selected[0].estado != 3 && mismo_usuario"
                v-model.trim="incidente.asunto"
                class="ml-2"
                hide-details
                outlined
                dense
              ></v-text-field>
              <v-spacer></v-spacer>
              <v-chip :color="inc_estados.find(e => e.id == selected[0].estado) ? inc_estados.find(e => e.id == selected[0].estado).color : ''">
                {{ selected[0].estado_nombre }}
              </v-chip>
            </v-card-title>
            <v-card-text class="pt-1">
              <v-row>
                <v-col cols="6" sm="3" md="2" class="pb-0">
                  <v-autocomplete
                    v-model="incidente.empresa"
                    label="Empresa"
                    item-text="nombre_corto"
                    item-value="id"
                    :items="empresas"
                    :readonly="selected[0].estado == 3"
                    :filled="selected[0].estado == 3"
                    hide-details
                    outlined
                    dense
                    @change="get_sucursales(); incidente.sucursal = null; incidente.local = null"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" class="pb-0">
                  <v-autocomplete
                    v-model="incidente.sucursal"
                    label="Sucursal"
                    item-text="nombre"
                    item-value="id"
                    :items="sucursales"
                    :readonly="selected[0].estado == 3"
                    :filled="selected[0].estado == 3"
                    hide-details
                    outlined
                    dense
                    @change="incidente.local = null"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  cols="12" sm="5"
                  class="pb-0"
                >
                  <v-autocomplete
                    v-model="incidente.local"
                    label="Local"
                    item-text="nombre"
                    item-value="id"
                    :items="locales.filter(l => l.sucursal == incidente.sucursal)"
                    :readonly="selected[0].estado == 3"
                    :filled="selected[0].estado == 3"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="4" class="pb-0">
                  <v-autocomplete
                    v-model="incidente.usuario_sol_id"
                    label="Solicitante"
                    item-text="nombre"
                    item-value="usuario_id"
                    :items="solicitantes.filter(s => ((s.usuario_id == 4341 && s.generico == 1) || s.usuario_id != 4341) && (s.usuario_id != 70 || !mismo_usuario))"
                    :readonly="selected[0].estado == 3 || !mismo_usuario"
                    :filled="selected[0].estado == 3 || !mismo_usuario"
                    hide-details
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" class="pb-0">
                  <v-text-field
                    v-model.trim="incidente.teamviewer"
                    label="Team Viewer"
                    :readonly="selected[0].estado == 3"
                    :filled="selected[0].estado == 3"
                    hide-details
                    outlined
                    dense
                  >
                    <template v-slot:append>
                      <v-icon
                        class="mt-1"
                        title="Copiar al portapapeles"
                        small
                        @click="copy_clipboard(incidente.teamviewer)"
                      >
                        fas fa-copy
                      </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" class="d-flex pt-2 pb-0 px-3" style="font-size: 13px;">
                  Cargado por {{ selected[0].usuario_creacion_nombre }}{{ incidente.usuario_sol_id == 4341 ? ` (${incidente.usuario_solicitante})` : '' }}
                  el {{ moment(selected[0].fecha_creacion).format('DD/MM/YY [a las] HH:mm') }}
                </v-col>
                <v-col cols="12" class="pt-1">
                  <v-textarea
                    v-model.trim="incidente.descripcion"
                    rows="3"
                    :readonly="selected[0].estado == 3 || !mismo_usuario"
                    :filled="selected[0].estado == 3 || !mismo_usuario"
                    hide-details
                    auto-grow
                    outlined
                    dense
                  ></v-textarea>
                </v-col>
                <v-col v-if="archivos.filter(a => a.comentario_id == null && a.respuesta != 1).length > 0" cols="12" class="pt-0">
                  <v-chip
                    v-for="(archivo, index) in archivos.filter(a => a.comentario_id == null && a.respuesta != 1)"
                    class="mb-1 mr-2"
                    :color="get_atributo_x_extension(archivo.extension, 'color')"
                    :key="index"
                    dark
                    @click="ver_archivo(archivo, '')"
                  >
                    <v-icon class="ml-1" left>{{ get_atributo_x_extension(archivo.extension, 'icono') }}</v-icon>
                    {{ archivo.nombre }}
                  </v-chip>
                </v-col>
                <v-col cols="12" md="8" class="d-flex align-center">
                  <v-autocomplete
                    v-model="incidente.tipo"
                    label="Tipo incidente"
                    item-text="nombre"
                    item-value="id"
                    :items="inc_tipos"
                    :readonly="selected[0].estado == 3"
                    :filled="selected[0].estado == 3"
                    hide-details
                    outlined
                    dense
                  ></v-autocomplete>
                  <v-btn
                    color="success"
                    class="ml-3"
                    title="Nuevo tipo"
                    :disabled="selected[0].estado == 3"
                    small
                    icon
                    @click="dialog_tipos_inc = true"
                  >
                    <v-icon>fas fa-plus</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12" md="4" class="d-flex justify-end align-center pt-md-3 pt-1">
                  <v-btn
                    v-if="selected[0].estado != 3"
                    color="indigo"
                    dark
                    @click="dialog_asignar = true"
                  >
                    <v-icon left>fas fa-user-plus</v-icon>
                    {{ selected[0].estado == 2 ? 'Rea' : 'A' }}signar incidente
                  </v-btn>
                </v-col>
                <v-col v-if="selected[0].estado == 2" cols="12" class="d-flex justify-end text-right body-1 py-1">
                  Asignado a {{ personal_mesa.find(p => p.usuario_id == incidente.usuario_asignado_id) ? personal_mesa.find(p => p.usuario_id == incidente.usuario_asignado_id).nombre : '' }}
                  el {{ moment(selected[0].fecha_asignacion).format('DD/MM/YY [a las] HH:mm') }}
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <!-- COMENTARIOS -->
          <v-card v-if="selected.length > 0 && !(comentarios.length == 0 && selected[0].respuesta)">
            <v-card-title class="py-2" style="word-break: normal">
              Comentarios
            </v-card-title>
            <v-card-text>
              <v-row v-if="load_comentario">
                <v-skeleton-loader
                  style="width: 100%;"
                  type="list-item-avatar-three-line"
                ></v-skeleton-loader>
                <v-skeleton-loader
                  style="width: 100%;"
                  type="actions"
                ></v-skeleton-loader>
              </v-row>
              <v-row v-else>
                <!-- "chat" -->
                <v-col
                  v-for="(comentario, index) in comentarios"
                  :key="index"
                  cols="12"
                  class="d-flex"
                >
                  <!-- icono usuario -->
                  <v-avatar
                    v-if="comentario.carga != 1 && !$vuetify.breakpoint.xs"
                    color="cyan"
                    class="mr-3"
                    style="font-size:22px; cursor: default"
                  >
                    <v-icon>fas fa-user</v-icon>
                  </v-avatar>
                  <v-card style="width: 100%;">
                    <v-card-text class="d-flex pb-1 pt-2 px-3">
                      <span class="font-weight-bold body-1">
                        {{ comentario.usuario }}
                      </span>
                      <v-spacer></v-spacer>
                      <span class="text-right">
                        {{ comentario.fecha }}
                      </span>
                    </v-card-text>
                    <!-- comentario -->
                    <v-card-text class="d-flex py-0 pb-1 px-3 body-1">
                      {{ comentario.comentario }}
                    </v-card-text>
                    <!-- archivos -->
                    <v-card-text class="py-1 px-3">
                      <v-chip
                        v-for="(archivo, index) in archivos.filter(a => a.comentario_id == comentario.id)"
                        class="mb-1 mr-2"
                        :color="get_atributo_x_extension(archivo.extension, 'color')"
                        :key="index"
                        small
                        dark
                        @click="ver_archivo(archivo, `/${comentario.id}`)"
                      >
                        <v-icon class="ml-1" small left>{{ get_atributo_x_extension(archivo.extension, 'icono') }}</v-icon>
                        {{ archivo.nombre }}
                      </v-chip>
                    </v-card-text>
                  </v-card>
                  <!-- icono admin -->
                  <v-avatar
                    v-if="comentario.carga == 1 && !$vuetify.breakpoint.xs"
                    :color="$vuetify.theme.dark ? 'lime darken-1' : 'lime'"
                    class="ml-3"
                    style="font-size:22px; cursor: default"
                  >
                    <v-icon>fas fa-desktop</v-icon>
                  </v-avatar>
                </v-col>
                <!-- enviar comentario -->
                <template v-if="!selected[0].respuesta">
                  <v-col cols="12" sm="10">
                    <v-textarea
                      v-model.trim="comentario"
                      rows="2"
                      hide-details
                      outlined
                      dense
                    ></v-textarea>
                    <div v-if="archivos_adjuntos.length > 0" class="mt-1">
                      <strong>
                        {{ archivos_adjuntos.length }} archivo{{ archivos_adjuntos.length == 1 ? '' : 's' }} adjunto{{ archivos_adjuntos.length == 1 ? '' : 's' }}:
                      </strong>
                      {{ archivos_adjuntos.map(a => a.name).join(', ') }}
                    </div>
                  </v-col>
                  <v-col cols="12" sm="2" class="d-flex justify-end align-center">
                    <v-btn
                      color="info"
                      class="mr-3"
                      title="Adjuntar archivos"
                      small
                      fab
                      @click="dialog_archivos = true"
                    >
                      <v-icon>fas fa-paperclip</v-icon>
                    </v-btn>
                    <v-btn
                      color="success"
                      class="mr-0 mr-md-3"
                      title="Enviar comentario"
                      small
                      fab
                      @click="enviar_comentario()"
                    >
                      <v-icon class="mr-1">fas fa-paper-plane</v-icon>
                    </v-btn>                  
                  </v-col>
                </template>
              </v-row>
            </v-card-text>
          </v-card>
          <!-- RESPUESTA -->
          <v-card v-if="selected.length > 0" class="mt-2">
            <v-card-title class="py-2 pb-0" style="word-break: normal">
              Respuesta
            </v-card-title>
            <div v-if="selected[0].respuesta" class="body-1 mx-4">
              Finalizado por {{ selected[0].usuario_finalizacion_nombre }} el {{ moment(selected[0].fecha_finalizacion).format('DD/MM/YY [a las] HH:mm') }}
            </div>
            <v-card-text class="pt-2">
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    v-model.trim="incidente.respuesta"
                    rows="3"
                    :readonly="selected[0].estado == 3"
                    :filled="selected[0].estado == 3"
                    hide-details
                    auto-grow
                    outlined
                    dense
                  ></v-textarea>
                  <div v-if="archivos_respuesta.length > 0" class="mt-1">
                    <strong>
                      {{ archivos_respuesta.length }} archivo{{ archivos_respuesta.length == 1 ? '' : 's' }} adjunto{{ archivos_respuesta.length == 1 ? '' : 's' }}:
                    </strong>
                    {{ archivos_respuesta.map(a => a.name).join(', ') }}
                  </div>
                </v-col>
                <v-col v-if="archivos.filter(a => a.comentario_id == null && a.respuesta == 1).length > 0" cols="12" class="pt-0">
                  <v-chip
                    v-for="(archivo, index) in archivos.filter(a => a.comentario_id == null && a.respuesta == 1)"
                    class="mb-1 mr-2"
                    :color="get_atributo_x_extension(archivo.extension, 'color')"
                    :key="index"
                    dark
                    @click="ver_archivo(archivo, '/respuesta')"
                  >
                    <v-icon class="ml-1" left>{{ get_atributo_x_extension(archivo.extension, 'icono') }}</v-icon>
                    {{ archivo.nombre }}
                  </v-chip>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-row class="d-flex justify-end pt-0 pb-3 mx-2">
                <v-btn
                  v-if="selected[0].estado == 3"
                  color="warning"
                  class="mb-2"
                  @click="dialog = true; titulo = `Reabierto Nº${selected[0].id} - ${selected[0].asunto}`"
                >
                  <v-icon left>far fa-life-ring</v-icon>
                  Reabrir incidente
                </v-btn>
                <v-btn
                  v-if="selected[0].estado != 3"
                  color="info"
                  title="Adjuntar archivos"
                  class="mb-2 ml-3"
                  @click="dialog_archivos_respuesta = true"
                >
                  <v-icon left>fas fa-paperclip</v-icon>
                  Adjuntar archivos
                </v-btn>
                <BtnConfirmar
                  v-if="selected[0].estado != 3"
                  nombre="Finalizar incidente"
                  icono="fas fa-flag-checkered"
                  color="success"
                  clase="mb-2 ml-3"
                  :texto="`¿Está seguro que desea finalizar el incidente <strong>Nº${selected[0].id} - ${selected[0].asunto}</strong>?`"
                  @action="finalizar()"
                />
              </v-row>
            </v-card-actions>
          </v-card>
        </section>
      </v-expand-transition>
    </v-col>
    <!-- COMPONENTES -->
    <NuevoIncidente
      v-model="dialog"
      :titulo="titulo"
      @actualizar="buscar()"
    />
    <PDFViewer
      v-model="dialog_pdf"
      :pdf="pdf"
      :nombre="nombre_ver"
      :titulo="nombre_ver"
    />
    <ImgViewer
      v-model="dialog_img"
      :img="img"
      :nombre="nombre_ver"
    />
    <AdjuntarArchivos
      v-model="dialog_archivos"
      :archivos.sync="archivos_adjuntos"
    />
    <AsignarIncidente
      v-model="dialog_asignar"
      :incidente="incidente"
      @actualizar="asignar"
    />
    <AdjuntarArchivosRespuesta
      v-model="dialog_archivos_respuesta"
      :archivos.sync="archivos_respuesta"
    />
    <TiposIncidentes
      v-model="dialog_tipos_inc"
      @seleccion="set_tipo"
    />
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import { tipos_archivos, getBase64, get_nombre_obj_arr, nvl } from '../../util/utils'
import BtnFiltro from '../../components/util/BtnFiltro'
import FechaPicker from '../../components/util/FechaPicker'
import BtnConfirmar from '../../components/util/BtnConfirmar'
import NuevoIncidente from '../../components/incidentes/NuevoIncidente'
import ImgViewer from '../../components/util/ImgViewer'
import PDFViewer from '../../util/plantillas/PDFViewer'
import AdjuntarArchivos from '../../components/incidentes/AdjuntarArchivos'
import AsignarIncidente from '../../components/incidentes/AsignarIncidente'
import AdjuntarArchivosRespuesta from '../../components/incidentes/AdjuntarArchivos'
import TiposIncidentes from '../../components/incidentes/TiposIncidentes'
import SearchDataTable from '../../components/util/SearchDataTable'
import moment from 'moment'

export default {
  data () {
    return {
      id: this.$route.params.id,
      moment: moment,
      panel: 0,
      load: false,
      load_comentario: false,
      mismo_usuario: false,
      dialog: false,
      dialog_img: false,
      dialog_pdf: false,
      dialog_archivos: false,
      dialog_archivos_respuesta: false,
      dialog_asignar: false,
      dialog_tipos_inc: false,
      comentario: '',
      nombre_ver: '',
      titulo: '',
      search: '',
      pdf: null,
      img: null,
      selected: [],
      archivos: [],
      sucursales: [],
      archivos_adjuntos: [],
      archivos_respuesta: [],
      comentarios: [],
      incidentes: [],
      headers: [
        { text: 'Número', value: 'id', align: 'center' },
        { text: 'Asunto', value: 'asunto', sortable: false },
        { text: 'Estado', value: 'estado_nombre' },
        { text: 'Fecha creación', value: 'fecha_creacion' },
        { text: 'Usuario solicitante', value: 'usuario_solicitante_nombre', sortable: false },
        { text: 'Fecha finalización', value: 'fecha_finalizacion' }
      ],
      incidente: {},
      filtro: {
        numero: '',
        asunto: '',
        estado: 1,
        asignado: null,
        solicitante: null,
        finalizacion: null,
        desde_sol: null,
        hasta_sol: null,
        desde_fin: null,
        hasta_fin: null
      }
    }
  },
  async created () {
    this.$store.state.loading = true
    await this.$store.dispatch('incidentes/get_estados')
    await this.$store.dispatch('incidentes/get_tipos')
    await this.$store.dispatch('incidentes/get_personal_mesa')
    this.$store.state.loading = false
    if (this.id) {
      this.filtro.numero = this.id
      this.filtro.estado = null
      this.buscar()
    }
  },
  computed: {
    ...mapState('incidentes', ['inc_estados', 'cargaInc', 'nombreUs', 'solicitantes', 'inc_tipos', 'personal_mesa']),
    ...mapState(['empresas', 'locales']),
  },
  components: {
    ImgViewer,
    PDFViewer,
    BtnFiltro,
    FechaPicker,
    BtnConfirmar,
    NuevoIncidente,
    AdjuntarArchivos,
    AsignarIncidente,
    AdjuntarArchivosRespuesta,
    TiposIncidentes,
    SearchDataTable
  },
  watch: {
    async selected (newVal, oldVal) {
      this.incidente = {}
      this.archivos = []
      this.comentarios = []
      this.archivos_adjuntos = []
      this.archivos_respuesta = []
      this.comentario = ''
      if (this.selected[0]) {
        if (this.selected[0].archivos) {
          this.archivos = this.selected[0].archivos
        }
        if (this.selected[0].comentarios) {
          this.comentarios = this.selected[0].comentarios
        }
      }
      // obtiene las imagenes del registro seleccionado solo cuando lo selecciona por primera vez
      if (newVal.length == 1 && ((oldVal.length == 0) || (oldVal[0].id != newVal[0].id))) {
        const id = await JSON.parse(JSON.stringify(this.selected[0].id))
        let selected = await this.incidentes.find(c => c.id == id)
        if (!selected.archivos) {
          this.$store.state.loading = true
          this.load_comentario = true
          await this.$store.dispatch('incidentes/get_archivos', selected.id)
            .then((res) => {
              selected.archivos = res.data
              this.archivos = selected.archivos
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error'
              })
            })
          this.$store.state.loading = false
          this.load_comentario = false
        }
        if (!selected.comentarios) {
          this.$store.state.loading = true
          this.load_comentario = true
          await this.$store.dispatch('incidentes/get_comentarios', selected.id)
            .then((res) => {
              selected.comentarios = res.data
              this.comentarios = selected.comentarios
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error'
              })
            })
          this.$store.state.loading = false
          this.load_comentario = false
        }
      }
      if (this.selected[0]) {
        this.mismo_usuario = this.selected[0].usuario_creacion == this.$store.state.username
        this.$store.state.loading = true
        this.incidente = await JSON.parse(JSON.stringify(this.selected[0]))
        await this.get_sucursales()
        this.$store.state.loading = false
      }
    }
  },
  methods: {
    async asignar (usuario, tipo) {
      this.archivos_respuesta = []
      this.incidente.respuesta = null
      this.incidente.tipo = JSON.parse(JSON.stringify(tipo))
      this.incidente.usuario_asignado_id = JSON.parse(JSON.stringify(usuario))
      this.incidente.fecha_asignacion = new Date()
      this.incidente.estado = 2
      this.incidente.estado_nombre = get_nombre_obj_arr(this.inc_estados, 'id', 2, 'nombre')
      let seleccionado = this.selected[0]
      Object.assign(seleccionado, this.incidente)
    },
    async finalizar () {
      // validaciones
      if (!this.incidente.usuario_sol_id) {
        return this.$store.dispatch('show_snackbar', {
          text: 'Debe seleccionar un solicitante',
          color: 'orange'
        })
      }
      if (!this.incidente.descripcion.trim() || !this.incidente.asunto) {
        return this.$store.dispatch('show_snackbar', {
          text: 'El asunto y/o la descripción no pueden ser nulos',
          color: 'orange'
        })
      }
      if (!this.incidente.teamviewer || !this.incidente.empresa || !this.incidente.sucursal) {
        return this.$store.dispatch('show_snackbar', {
          text: 'Complete la empresa, sucursal y/o teamviewer para continuar',
          color: 'orange'
        })
      }
      if (!this.incidente.tipo) {
        return this.$store.dispatch('show_snackbar', {
          text: 'Seleccione un tipo de incidente',
          color: 'orange'
        })
      }
      if (!nvl(this.incidente.respuesta, '').trim()) {
        return this.$store.dispatch('show_snackbar', {
          text: 'La respuesta no puede estar vacía',
          color: 'orange'
        })
      }

      // valido si en algun lado de la respuesta puso adjunto para avisarle por si no adjuntó nada
      const tiene_adjunto = this.incidente.respuesta.toLowerCase().indexOf('adjunto') >= 0
      const tiene_archivo = this.incidente.respuesta.toLowerCase().indexOf('archivo') >= 0
      if ((tiene_adjunto || tiene_archivo) && this.archivos_respuesta.length == 0) {
        let ups_me_olvide_el_archivo = false
        let abrir_modal = false
        await this.$swal.fire({
          icon: 'warning',
          html: `Se encontró la palabra <strong>${tiene_archivo ? 'archivo' : ''} ${tiene_adjunto ? 'adjunto' : ''}</strong> en la respuesta
          pero no se seleccionó ningún archivo.<div style="font-size: 20px; margin-top: 12px"><strong>¿Desea finalizar el incidente de todas formas?</strong></div>`,
          confirmButtonText: 'Sí, cargar de todas formas',
          denyButtonText: 'No, seleccionar archivos',
          denyButtonColor: '#3085d6',
          showDenyButton: true,
          focusDeny: true
        })
          .then((res) => {
            abrir_modal = res.isDenied
            // si pone que no o hace click fuera del modal no hace nada
            if (!res.isConfirmed) {
              ups_me_olvide_el_archivo = true
            }
          })
        if (abrir_modal) this.dialog_archivos = true
        if (ups_me_olvide_el_archivo) return
      }

      let archivos = []
      // convierto los archivos en base64
      this.$store.state.loading = true
      for (const file of this.archivos_respuesta) {
        await getBase64(file).then(data => {
          archivos.push({
            file: data.split(',')[1],
            name: file.name
          })
        })
      }

      // finalizo el incidente
      await this.$store.dispatch('incidentes/finalizar', {
        id: this.incidente.id,
        tipo: this.incidente.tipo,
        respuesta: this.incidente.respuesta,
        empresa: this.incidente.empresa,
        sucursal: this.incidente.sucursal,
        local: this.incidente.local,
        teamviewer: this.incidente.teamviewer,
        asunto: this.incidente.asunto,
        descripcion: this.incidente.descripcion,
        solicitante: this.incidente.usuario_sol_id,
        archivos: archivos
      })
        .then((res) => {
          // agrego los archivos que subieron al array general de archivos
          for (const archivo of res.archivos) {
            this.archivos.push(archivo)
          }
          this.incidente.archivos = JSON.parse(JSON.stringify(this.archivos))
          this.incidente.fecha_finalizacion = new Date()
          this.incidente.usuario_finalizacion_nombre = get_nombre_obj_arr(this.personal_mesa, 'usuario', this.$store.state.username, 'nombre')
          this.incidente.estado = 3
          this.incidente.estado_nombre = get_nombre_obj_arr(this.inc_estados, 'id', 3, 'nombre')
          let seleccionado = this.selected[0]
          Object.assign(seleccionado, this.incidente)
          this.$swal.fire({
            icon: res.errores ? 'warning' : 'success',
            title: res.message,
            html: res.errores
          })
          // envia la notificacion push
          this.$store.dispatch('enviar_push')
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async buscar () {
      this.incidentes = []
      this.selected = []
      this.search = ''
      this.load = true
      await this.$store.dispatch('incidentes/get_incidentes_mesa', {
        id: this.filtro.numero,
        asunto: this.filtro.asunto,
        estado: this.filtro.estado ? this.filtro.estado : '',
        us_asignado: this.filtro.asignado ? this.filtro.asignado : '',
        us_solicit: this.filtro.solicitante ? this.solicitantes.find(s => s.personal_id == this.filtro.solicitante).usuario_id : '',
        us_finaliz: this.filtro.finalizacion ? this.filtro.finalizacion : '',
        desde_sol: this.filtro.desde_sol,
        hasta_sol: this.filtro.hasta_sol,
        desde_fin: this.filtro.desde_fin,
        hasta_fin: this.filtro.hasta_fin
      })
        .then((res) => {
          this.incidentes = res.data
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.load = false
    },
    async enviar_comentario () {
      // validaciones
      if (!this.incidente.usuario_sol_id) {
        return this.$store.dispatch('show_snackbar', {
          text: 'Debe seleccionar un solicitante',
          color: 'orange'
        })
      }
      if (!this.incidente.descripcion.trim() || !this.incidente.asunto) {
        return this.$store.dispatch('show_snackbar', {
          text: 'El asunto y/o la descripción no pueden ser nulos',
          color: 'orange'
        })
      }
      if (!this.incidente.teamviewer || !this.incidente.empresa || !this.incidente.sucursal) {
        return this.$store.dispatch('show_snackbar', {
          text: 'Complete la empresa, sucursal y/o teamviewer para continuar',
          color: 'orange'
        })
      }
      if (!this.incidente.tipo) {
        return this.$store.dispatch('show_snackbar', {
          text: 'Seleccione un tipo de incidente',
          color: 'orange'
        })
      }
      if (!this.comentario.trim()) {
        return this.$store.dispatch('show_snackbar', {
          text: 'No puede enviar un comentario vacio',
          color: 'orange'
        })
      }

      // valido si en algun lado del comentario puso adjunto para avisarle por si no adjuntó nada
      const tiene_adjunto = this.comentario.toLowerCase().indexOf('adjunto') >= 0
      const tiene_archivo = this.comentario.toLowerCase().indexOf('archivo') >= 0
      if ((tiene_adjunto || tiene_archivo) && this.archivos_adjuntos.length == 0) {
        let ups_me_olvide_el_archivo = false
        let abrir_modal = false
        await this.$swal.fire({
          icon: 'warning',
          html: `Se encontró la palabra <strong>${tiene_archivo ? 'archivo' : ''} ${tiene_adjunto ? 'adjunto' : ''}</strong> en el comentario pero no se
          seleccionó ningún archivo.<div style="font-size: 20px; margin-top: 12px"><strong>¿Desea enviar el comentario de todas formas?</strong></div>`,
          confirmButtonText: 'Sí, enviar de todas formas',
          denyButtonText: 'No, seleccionar archivos',
          denyButtonColor: '#3085d6',
          showDenyButton: true,
          focusDeny: true
        })
          .then((res) => {
            abrir_modal = res.isDenied
            // si pone que no o hace click fuera del modal no hace nada
            if (!res.isConfirmed) {
              ups_me_olvide_el_archivo = true
            }
          })
        if (abrir_modal) this.dialog_archivos = true
        if (ups_me_olvide_el_archivo) return
      }
      this.$store.state.loading = true
      let archivos = []
      // convierto los archivos en base64
      for (const file of this.archivos_adjuntos) {
        await getBase64(file).then(data => {
          archivos.push({
            file: data.split(',')[1],
            name: file.name
          })
        })
      }

      await this.$store.dispatch('incidentes/comentario', {
        id: this.incidente.id,
        comentario: this.comentario,
        tipo: this.incidente.tipo,
        empresa: this.incidente.empresa,
        sucursal: this.incidente.sucursal,
        local: this.incidente.local,
        teamviewer: this.incidente.teamviewer,
        asunto: this.incidente.asunto,
        descripcion: this.incidente.descripcion,
        solicitante: this.incidente.usuario_sol_id,
        archivos: archivos
      })
        .then((res) => {
          this.$swal.fire({
            icon: res.errores ? 'warning' : 'success',
            title: res.message,
            html: res.errores
          })
          // agrego el comentario
          this.comentarios.push({
            id: res.id,
            comentario: this.comentario,
            usuario: this.nombreUs,
            fecha: moment(new Date()).format('DD/MM/YY HH:mm'),
            carga: this.cargaInc ? 1 : null
          })
          this.selected[0].comentarios = JSON.parse(JSON.stringify(this.comentarios))

          // agrego los archivos que subieron al array general de archivos
          for (const archivo of res.archivos) {
            this.archivos.push(archivo)
          }
          this.selected[0].archivos = JSON.parse(JSON.stringify(this.archivos))

          // si no tenia un usuario asignado al enviar el comentario, lo autoasigna
          if (res.usuario_asignado_id) {
            this.asignar(res.usuario_asignado_id, this.incidente.tipo)
          }

          this.comentario = ''
          this.archivos_adjuntos = []
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async get_sucursales () {
      this.sucursales = []
      if (this.incidente.empresa) {
        this.$store.state.loading = true
        await this.$store.dispatch('incidentes/get_sucursales', this.incidente.empresa)
          .then((res) => {
            this.sucursales = res.data
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
        this.$store.state.loading = false
      }
    },
    ver_archivo (archivo, ruta) {
      const tipo = this.get_atributo_x_extension(archivo.extension, 'tipo')
      this.nombre_ver = archivo.nombre
      if (tipo == 'IMG') {
        this.img = `data:${this.get_atributo_x_extension(archivo.extension, 'mime')};base64,${archivo.file}`
        this.dialog_img = true
      }
      else if (tipo == 'PDF') {
        this.pdf = `data:application/pdf;base64,${archivo.file}`
        this.dialog_pdf = true
      }
      else {
        const routeData = this.$router.resolve({ path: `/descargarArchivo?path=/incidentes/${this.incidente.id}${ruta}/${archivo.nombre}` })
        window.open(routeData.href, '_blank')
      }
    },
    rowClick (item, row) {
      let selectState = (row.isSelected) ? false : true
      row.select(selectState)
      if (!row.isSelected) this.$vuetify.goTo('#detalle')
    },
    // obtiene un atributo especifico del array tipos_archivos en base a la extension del archivo indicado
    get_atributo_x_extension (extension, atributo) {
      return tipos_archivos.find(a => a.extension == extension) ? tipos_archivos.find(a => a.extension == extension)[atributo] : ''
    },
    copy_clipboard (texto) {
      navigator.clipboard.writeText(texto)
    },
    set_tipo (tipo) {
      this.incidente.tipo = tipo
    },
    limpiar () {
      this.search = ''
      this.filtro = {
        numero: '',
        asunto: '',
        estado: 1,
        asignado: null,
        solicitante: null,
        finalizacion: null,
        desde_sol: null,
        hasta_sol: null,
        desde_fin: null,
        hasta_fin: null
      }
    }
  }
}
</script>